import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M2788 4124 c-192 -46 -314 -184 -325 -368 -16 -242 90 -381 425 -559
78 -42 162 -90 186 -106 86 -60 123 -126 113 -208 -6 -52 -29 -88 -78 -121
-30 -21 -43 -23 -114 -20 -59 2 -93 10 -128 27 -98 48 -186 166 -223 299 l-17
62 -110 0 -109 0 7 -92 c8 -104 46 -325 70 -406 9 -29 23 -56 31 -59 8 -3 33
4 57 15 94 43 112 47 159 29 202 -75 422 -73 577 5 250 127 313 447 129 661
-55 66 -138 121 -316 214 -184 96 -232 129 -269 184 -24 37 -28 52 -28 115 0
78 13 107 68 148 34 25 123 33 183 16 91 -26 183 -139 215 -263 l11 -47 89 0
89 0 0 143 c0 79 -5 170 -10 203 -12 66 -38 144 -49 144 -4 0 -47 -12 -96 -26
-72 -21 -93 -23 -113 -14 -85 38 -312 52 -424 24z"/>
<path d="M1631 4075 c-12 -25 -21 -55 -21 -68 0 -22 6 -25 50 -30 67 -8 100
-25 127 -66 l24 -35 -3 -500 -3 -501 -24 -42 c-44 -77 -141 -103 -226 -62 -76
38 -121 155 -112 294 3 44 6 87 8 95 3 13 -19 15 -151 16 -85 1 -161 -1 -167
-4 -16 -5 -18 -207 -3 -297 16 -99 127 -305 164 -305 8 0 31 9 50 21 33 19 36
20 83 4 26 -9 91 -22 143 -28 234 -26 451 67 533 229 53 105 57 154 57 643 0
268 4 460 10 475 12 33 66 66 107 66 31 0 32 1 26 33 -3 17 -14 49 -25 70
l-18 37 -304 0 -304 0 -21 -45z"/>
<path d="M3766 1997 c-11 -8 -17 -23 -14 -37 2 -19 12 -27 43 -36 29 -8 41
-17 43 -32 3 -18 -2 -22 -24 -22 -15 0 -36 7 -46 17 -17 15 -18 15 -18 -1 0
-24 45 -42 78 -30 21 7 27 16 27 39 0 27 -5 32 -43 44 -31 9 -42 18 -40 30 5
23 49 27 65 5 10 -14 13 -15 13 -3 0 36 -49 51 -84 26z"/>
<path d="M3890 1947 c0 -70 17 -97 58 -97 41 0 62 34 62 100 0 33 -4 60 -10
60 -6 0 -10 -28 -10 -65 0 -61 -2 -65 -25 -71 -46 -12 -55 0 -55 71 0 37 -4
65 -10 65 -6 0 -10 -28 -10 -63z"/>
<path d="M4040 1930 l0 -80 44 0 c53 0 81 22 71 54 -4 12 -7 34 -6 50 1 42
-15 56 -65 56 l-44 0 0 -80z m88 38 c-2 -18 -11 -24 -35 -26 -30 -3 -33 -1
-33 22 0 23 4 26 36 26 31 0 35 -3 32 -22z m7 -73 c0 -16 -8 -21 -37 -23 -35
-3 -38 -1 -38 23 0 24 3 26 38 23 29 -2 37 -7 37 -23z"/>
<path d="M4190 1860 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
